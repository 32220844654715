<template>
  <ul class="navbar-nav" :class="classNames" @click="onClickNavbarItems">
    
    <li class="nav-item">
      <router-link
        class="nav-link"
        :to="{ name: 'dashboard' }"
        :class="{
          'menu-link-active': $route.name.indexOf('dashboard') !== -1
        }"
        >Dashboard</router-link
      >
    </li>
    <li class="nav-item">
      <router-link
        class="nav-link"
        :to="{ name: 'sales.documents' }"
        :class="{
          'menu-link-active': $route.name.indexOf('sales') !== -1
        }"
        >Ventas
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        class="nav-link"
        :to="{ name: 'wallet' }"
        :class="{
          'menu-link-active': $route.name.indexOf('wallet') !== -1
        }"
        >Wallet
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        class="nav-link"
        :to="{ name: 'business.main' }"
        :class="{
          'menu-link-active': $route.name.indexOf('business') !== -1
        }"
        >Directorio</router-link
      >
    </li>
    <li class="nav-item">
      <router-link
        class="nav-link"
        :to="{ name: 'catalogue.products' }"
        :class="{
          'menu-link-active': $route.name.indexOf('catalogue') !== -1
        }"
        >Catálogo
      </router-link>
    </li>
    <!-- <li class="nav-item">
      <router-link
        class="nav-link"
        :to="{ name: 'agreements' }"
        :class="{
          'menu-link-active': $route.name.indexOf('agreements') !== -1
        }"
        >Contratos</router-link
      >
    </li> -->
    <li class="nav-item">
      <router-link
        class="nav-link"
        :to="{ name: 'reports' }"
        :class="{
          'menu-link-active': $route.name.indexOf('reports') !== -1
        }"
        >Reportes</router-link
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: "NavbarItemsComponent",
  methods: {
    onClickNavbarItems() {
      this.$emit("onClickNavbarItems");
    }
  },
  props: {
    classNames: {
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar-light .navbar-nav .nav-link {
  font-weight: bolder !important;
}
.nav-item {
  margin-right: 1rem;
  font-size: 14px;
}
.mobile li a {
  color:#fff !important;
  text-align: center;
  font-size: 1.3rem;
  margin-top: 0.8rem;
}
</style>
