<template>
  <div class="content-fluid">
    <main class="wrapper-main">
      <BartopPlan />
      <DashboardHeaderComponent />
      <DashboardContentComponent />
      <p class="m-0 p-4 text-center">{{ date }}</p>
    </main>
  </div>
</template>

<script>
import moment from "moment";
import DashboardHeaderComponent from "./DashboardHeaderComponent";
import DashboardContentComponent from "./DashboardContentComponent";
import BartopPlan from "@/modules/dashboard/plans/components/BartopPlan";
import { onLoadScriptSoporte } from "@/assets/utils/soporte";

export default {
  name: "DashboardLayout",
  data: () => ({
    date: moment().format("MMMM Do YYYY, h:mm a")
  }),
  components: {
    DashboardHeaderComponent,
    DashboardContentComponent,
    BartopPlan
  },
  mounted() {
    onLoadScriptSoporte();
    window.onload = function() {
      const element = document.querySelector(
        ".amo-button-holder.amo-horisontal"
      );
      if (element) {
        element.style.position = "fixed";
        element.style.right = "50px";
        element.style.bottom = "30px";
        // Añade más estilos según sea necesario
      }
    };
  }
};
</script>

<style scoped lang="scss"></style>
