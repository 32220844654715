var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"navbar-nav",class:_vm.classNames,on:{"click":_vm.onClickNavbarItems}},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
        'menu-link-active': _vm.$route.name.indexOf('dashboard') !== -1
      },attrs:{"to":{ name: 'dashboard' }}},[_vm._v("Dashboard")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
        'menu-link-active': _vm.$route.name.indexOf('sales') !== -1
      },attrs:{"to":{ name: 'sales.documents' }}},[_vm._v("Ventas ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
        'menu-link-active': _vm.$route.name.indexOf('wallet') !== -1
      },attrs:{"to":{ name: 'wallet' }}},[_vm._v("Wallet ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
        'menu-link-active': _vm.$route.name.indexOf('business') !== -1
      },attrs:{"to":{ name: 'business.main' }}},[_vm._v("Directorio")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
        'menu-link-active': _vm.$route.name.indexOf('catalogue') !== -1
      },attrs:{"to":{ name: 'catalogue.products' }}},[_vm._v("Catálogo ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
        'menu-link-active': _vm.$route.name.indexOf('reports') !== -1
      },attrs:{"to":{ name: 'reports' }}},[_vm._v("Reportes")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }