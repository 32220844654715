<template>
  <section class="bartop-plan" v-if="!isPlanSubscriptions">
    <p class="m-0 text-color-write">
      Prueba el plan personal por 15 días
    </p>
    <span
      class="cursor-point text-color-secondary ml-3 mr-2"
      @click="trialToSubscription"
      >Activar ahora</span
    >
    <img :src="icoRocket" alt="ico rocket" />
  </section>
</template>

<script>
import { icoRocket } from "@/services/resources";
import payService from "../services/payService";

export default {
  name: "BartopPlan",
  data() {
    return {
      icoRocket
    };
  },
  computed: {
    isPlanSubscriptions() {
      return this.$store.getters.isPlanSubscriptions;
    }
  },
  methods: {
    async trialToSubscription() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await payService.trialToSubscription().finally(() => {
          this.$store.dispatch("toggleRequestLoading");
        });
        const { success, message, user } = response.data;

        if (success) {
          this.$notifications.success({
            message
          });

          this.$store.dispatch("userBusinessUpdated", user.user_business);
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.bartop-plan {
  background: #2b3e9a !important;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
</style>
