<template>
  <section>
    <ul class="d-flex list-unstyled pl-2 m-0">
      <template v-if="$route.name.indexOf('configurations') !== -1">
        <li class="nav-item py-2 mt-1">
          <router-link
            class="nav-link text-color-black font-bold"
            :class="{
              'submenu-active': $route.name.indexOf('configurations') !== -1
            }"
            :to="{ name: 'configurations' }"
          >
            Configuración
          </router-link>
        </li>
        <li class="nav-item py-2 mt-1">
          <router-link
            class="nav-link text-color-black font-bold"
            :class="{
              'submenu-active': $route.name.indexOf('plans') !== -1
            }"
            :to="{ name: 'plans' }"
          >
            <el-badge value="Empresa" id="badge-plan" type="primary">
              <span>Actualizar Plan</span>
            </el-badge>
          </router-link>
        </li>
      </template>
      <template v-if="$route.name.indexOf('catalogue') !== -1">
        <li class="nav-item py-2 mt-1">
          <router-link
            class="nav-link text-color-black font-bold"
            id="submenu-catalog-products"
            :class="{
              'submenu-active': $route.name.indexOf('products') !== -1
            }"
            :to="{ name: 'catalogue.products' }"
          >
            Productos
          </router-link>
        </li>
        <li class="nav-item py-2 mt-1">
          <router-link
            class="nav-link text-color-black font-bold"
            id="submenu-catalog-categories"
            :class="{
              'submenu-active': $route.name.indexOf('categories') !== -1
            }"
            :to="{ name: 'catalogue.categories' }"
          >
            Categorías
          </router-link>
        </li>
        <li class="nav-item py-2 mt-1">
          <router-link
            class="nav-link text-color-black font-bold"
            id="submenu-catalog-categories"
            :class="{
              'submenu-active': $route.name.indexOf('movements') !== -1
            }"
            :to="{ name: 'catalogue.movements' }"
          >
            Movimientos
          </router-link>
        </li>
        <li class="nav-item py-2 mt-1">
          <router-link
            class="nav-link text-color-black font-bold"
            id="submenu-catalog-attributes"
            :class="{
              'submenu-active': $route.name.indexOf('attributes') !== -1
            }"
            :to="{ name: 'catalogue.attributes' }"
            v-if="false"
          >
            Atributos
          </router-link>
        </li>
      </template>
      <!-- <template v-if="$route.name.indexOf('sales') !== -1">
        <li class="nav-item py-2">
          <router-link
            class="nav-link text-color-black font-bold"
            :class="{
              'submenu-active': $route.name.indexOf('documents') !== -1
            }"
            :to="{ name: 'sales.documents' }"
          >
            Documentos
          </router-link>
        </li>
        <li class="nav-item py-2">
          <router-link
            class="nav-link text-color-black font-bold"
            :class="{
              'submenu-active': $route.name.indexOf('balance') !== -1
            }"
            :to="{ name: 'sales.balance.view' }"
          >
            Balance
          </router-link>
        </li>
      </template> -->
      <template v-if="$route.name.indexOf('plans') !== -1">
        <li class="nav-item py-2">
          <router-link
            class="nav-link text-color-black font-bold"
            :class="{
              'submenu-active': $route.name.indexOf('plans') !== -1
            }"
            :to="{ name: 'plans' }"
          >
            Planes
          </router-link>
        </li>
        <li class="nav-item py-2">
          <router-link
            class="nav-link text-color-black font-bold"
            :class="{
              'submenu-active': $route.name.indexOf('additionals') !== -1
            }"
            :to="{ name: 'plans.additionals' }"
          >
            Adicionales
          </router-link>
        </li>

        <li class="nav-item py-2">
          <router-link
            class="nav-link text-color-black font-bold"
            :class="{
              'submenu-active': $route.name.indexOf('history') !== -1
            }"
            :to="{ name: 'plans.history' }"
          >
            Historial
          </router-link>
        </li>
      </template>
    </ul>
  </section>
</template>

<script>
export default {
  name: "SubMenuComponent"
};
</script>

<style lang="scss" scoped>
.submenu-active {
  color: #31355c !important;
  font-weight: bold;
}

#badge-plan {
  .el-badge__content {
    background: #3e53bd !important;
  }
}
</style>
