<template>
  <transition-group
    tag="div"
    class="user-content-container"
    :name="!toggleBusiness ? 'slideback' : 'slide'"
  >
    <div v-if="!toggleBusiness" key="1" class="toggle-block">
      <div
        class="business-selected p-1 business-main"
        @click="onToggleBusiness"
      >
        <div
          class="image-logo rounded-circle"
          v-if="user.user_business.business_logo != null"
        >
          <img :src="user.user_business.business_logo" alt="" />
        </div>
        <TextAvatarComponent
          :text="user.user_business.business_name"
          class="p-0"
          v-else
        />
        <h5 class="font-medium mx-2 mb-0">
          {{ user.user_business.business_name }}
        </h5>
        <button class="btn md__btn-link bgt p-0 m-0">
          <i
            class="el-icon-arrow-right i-arrow-right font-black"
            width="32"
          ></i>
        </button>
      </div>

      <ul class="profile-menu font-medium navbar-nav p-2">
        <li>
          <router-link
            :to="{ path: '/dashboard/negocio', query: { action: 'editar' } }"
            ><img :src="iconBusiness" alt="" /> Negocio</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'configurations' }"
            ><img :src="iconConfig" alt="" /> Configuración</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'plans' }">
            <img :src="iconPlans" alt="" />Actualizar plan</router-link
          >
        </li>
        <!-- <li>
          <router-link to="">Referir a un amigo</router-link>
        </li> -->
        <li>
          <router-link to="" @click.native="logout"
            ><img :src="iconLogout" alt="" />Cerrar sesión</router-link
          >
        </li>
      </ul>
    </div>

    <div v-else key="2" class="toggle-block business-list">
      <div class="business-back px-2 mt-2" @click="onToggleBusiness">
        <i class="el-icon-back font-black i-arrow-left-back"></i>
        <h5 class="h5">Configuraciones de negocio</h5>
      </div>
      <div class="btn-content">
        <ButtonPrimaryComponent
          title="Nuevo perfil de negocio +"
          class="btn-add-business p-3 font-semi-bold"
          @onSaveClick="goRoute('/dashboard/negocio', 'nuevo')"
        />
      </div>
      <ul class="profile-menu navbar-nav p-2">
        <li
          v-bind:key="index"
          v-for="(item, index) in user.businesses"
          @click="setBusinessToCurrentUser(item)"
          :class="item.id == user.user_business.id ? 'business-selected' : ''"
        >
          <div class="image-logo rounded-circle">
            <img
              :src="item.business_logo"
              alt=""
              v-if="item.business_logo != null"
            />
            <TextAvatarComponent :text="item.business_name" v-else />
          </div>
          <h5 class="h5 business-name">{{ item.business_name }}</h5>
        </li>
      </ul>
    </div>
  </transition-group>
</template>

<script>
import {
  iconBusiness,
  iconConfig,
  iconLogout,
  iconPlans
} from "@/services/resources";
import ButtonPrimaryComponent from "../../../modules/dashboard/components/ButtonPrimary";
import TextAvatarComponent from "../../../modules/dashboard/sales/components/TextAvatar";
export default {
  name: "UserContentPopover",
  data: () => {
    return {
      iconBusiness,
      iconConfig,
      iconLogout,
      iconPlans,
      toggleBusiness: false
    };
  },
  props: ["user"],
  components: {
    ButtonPrimaryComponent,
    TextAvatarComponent
  },
  methods: {
    onToggleBusiness() {
      this.toggleBusiness = !this.toggleBusiness;
    },
    setBusinessToCurrentUser(business) {
      this.$store.dispatch("userBusinessUpdated", business);
      this.$forceUpdate();
      this.$router.push({ name: "dashboard" }).catch();
    },
    goRoute(page, param) {
      this.$router
        .push({
          path: page,
          query: { action: param }
        })
        .catch(error => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "login" });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.user-content-container {
  overflow: hidden;
  position: relative;
  height: 270px;
}

.image-logo {
  padding: 0;
  width: 35px;
  height: 35px;
  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
}
.btn-content {
  padding: 10px;
  .btn {
    width: 100% !important;
  }
}
.toggle-block {
  position: absolute;
  width: 100%;
}
.business-list {
  overflow-y: auto;
  height: 270px;
  li {
    display: flex;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    h5 {
      margin: 0;
      padding: 10px;
    }
  }
}

.business-main {
  border-radius: 5px;
  margin: 5px 10px;
}

.business-selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgrey;
  cursor: pointer;
  border-radius: 5px;
  h5 {
    font-size: 15px;
  }
}

.business-back {
  display: flex;
  margin: 5px 10px;
  cursor: pointer;
  .el-icon-back {
    font-size: 28px;
  }
  h5 {
    font-size: 16px;
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.slideback-leave-active,
.slideback-enter-active {
  transition: 0.5s;
}
.slideback-enter {
  transform: translate(-100%, 0);
}
.slideback-leave-to {
  transform: translate(100%, 0);
}

.btn-add-business {
  background-color: #defff1 !important;
  color: #0faea8 !important;
}

.btn-add-business:hover {
  color: #defff1 !important;
  background-color: #0faea8 !important;
}

.i-arrow-right {
  font-size: 1.5rem;
  margin-right: auto;
}
</style>
