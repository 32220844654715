export const onLoadScriptSoporte = () => {
  let dataPayphoneScript = document.createElement("script");
  dataPayphoneScript.type = "text/javascript";
  dataPayphoneScript.defer = true;
  dataPayphoneScript.text = `
 
  (function(a,m,o,c,r,m){a[m]={id:"1019309",hash:"76a63efbbfe74da10e33a70e24552d2a5b92430a2329a2199c9168654c0df997",locale:"es",setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.kommo.com/js/button.js';d.head&&d.head.appendChild(s)}(window,0,'crmPlugin',0,0,'crm_plugin'))
    
    
  `;
  document.head.appendChild(dataPayphoneScript);
};
