<template>
  <div class="navbar-responsive">
    <button class="navbar-toggler" type="button" @click="onToogleNavbar">
      <span class="navbar-toggler-icon navbar-light"></span>
    </button>

    <el-drawer :visible.sync="toogleNavbar" :direction="direction">
      <template slot="title">
        <LogoComponent width="10px" class="" color="white" />
      </template>
      <NavbarItemsComponent @onClickNavbarItems="onClickNavbarItems" classNames="mobile"/>
    </el-drawer>
  </div>
</template>

<script>
import NavbarItemsComponent from "./NavbarItems";
import LogoComponent from "@/components/Logo/Logo";

export default {
  name: "NavbarResponsiveComponent",
  data() {
    return {
      toogleNavbar: false,
      direction: "ltr"
    };
  },
  computed: {},
  methods: {
    onToogleNavbar() {
      this.toogleNavbar = !this.toogleNavbar;
    },
    onClickNavbarItems() {
      this.onToogleNavbar();
    }
  },
  components: {
    NavbarItemsComponent,
    LogoComponent
  }
};
</script>

<style></style>
