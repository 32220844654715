var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('ul',{staticClass:"d-flex list-unstyled pl-2 m-0"},[(_vm.$route.name.indexOf('configurations') !== -1)?[_c('li',{staticClass:"nav-item py-2 mt-1"},[_c('router-link',{staticClass:"nav-link text-color-black font-bold",class:{
            'submenu-active': _vm.$route.name.indexOf('configurations') !== -1
          },attrs:{"to":{ name: 'configurations' }}},[_vm._v(" Configuración ")])],1),_c('li',{staticClass:"nav-item py-2 mt-1"},[_c('router-link',{staticClass:"nav-link text-color-black font-bold",class:{
            'submenu-active': _vm.$route.name.indexOf('plans') !== -1
          },attrs:{"to":{ name: 'plans' }}},[_c('el-badge',{attrs:{"value":"Empresa","id":"badge-plan","type":"primary"}},[_c('span',[_vm._v("Actualizar Plan")])])],1)],1)]:_vm._e(),(_vm.$route.name.indexOf('catalogue') !== -1)?[_c('li',{staticClass:"nav-item py-2 mt-1"},[_c('router-link',{staticClass:"nav-link text-color-black font-bold",class:{
            'submenu-active': _vm.$route.name.indexOf('products') !== -1
          },attrs:{"id":"submenu-catalog-products","to":{ name: 'catalogue.products' }}},[_vm._v(" Productos ")])],1),_c('li',{staticClass:"nav-item py-2 mt-1"},[_c('router-link',{staticClass:"nav-link text-color-black font-bold",class:{
            'submenu-active': _vm.$route.name.indexOf('categories') !== -1
          },attrs:{"id":"submenu-catalog-categories","to":{ name: 'catalogue.categories' }}},[_vm._v(" Categorías ")])],1),_c('li',{staticClass:"nav-item py-2 mt-1"},[_c('router-link',{staticClass:"nav-link text-color-black font-bold",class:{
            'submenu-active': _vm.$route.name.indexOf('movements') !== -1
          },attrs:{"id":"submenu-catalog-categories","to":{ name: 'catalogue.movements' }}},[_vm._v(" Movimientos ")])],1),_c('li',{staticClass:"nav-item py-2 mt-1"},[(false)?_c('router-link',{staticClass:"nav-link text-color-black font-bold",class:{
            'submenu-active': _vm.$route.name.indexOf('attributes') !== -1
          },attrs:{"id":"submenu-catalog-attributes","to":{ name: 'catalogue.attributes' }}},[_vm._v(" Atributos ")]):_vm._e()],1)]:_vm._e(),(_vm.$route.name.indexOf('plans') !== -1)?[_c('li',{staticClass:"nav-item py-2"},[_c('router-link',{staticClass:"nav-link text-color-black font-bold",class:{
            'submenu-active': _vm.$route.name.indexOf('plans') !== -1
          },attrs:{"to":{ name: 'plans' }}},[_vm._v(" Planes ")])],1),_c('li',{staticClass:"nav-item py-2"},[_c('router-link',{staticClass:"nav-link text-color-black font-bold",class:{
            'submenu-active': _vm.$route.name.indexOf('additionals') !== -1
          },attrs:{"to":{ name: 'plans.additionals' }}},[_vm._v(" Adicionales ")])],1),_c('li',{staticClass:"nav-item py-2"},[_c('router-link',{staticClass:"nav-link text-color-black font-bold",class:{
            'submenu-active': _vm.$route.name.indexOf('history') !== -1
          },attrs:{"to":{ name: 'plans.history' }}},[_vm._v(" Historial ")])],1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }