<template>
  <div class="header bg-color-write border-bottom sticky-custom">
    <header class="d-flex line-bottom">
      <div class="d-flex align-content-center">
        <LogoComponent
          width="100%"
          height="100%"
          class="mr-4 image-logo-nav"
          color="blue"
          @onClick="onClickLogo"
        />
        <nav class="navbar navbar-expand-lg navbar-light md__nav_main">
          <NavbarResponsiveComponent />
          <div class="collapse navbar-collapse" id="navbarNav">
            <NavbarItemsComponent classNames="small" />
          </div>
        </nav>
      </div>

      <section class="px-3 px-lg-4 md__nav_main-items-right pt-1">
        <div class="d-flex justify-content-between ">
          <div class="d-flex align-items-center">
            <div class="business-selected p-1 business-main">
              <div
                class="image-logo-header rounded-circle"
                v-if="getUser.user_business.business_logo != null"
              >
                <img :src="getUser.user_business.business_logo" alt="" />
              </div>
              <TextAvatarComponent
                :text="getUser.user_business.business_name"
                class="p-0"
                style="width: 30px !important; height: 30px !important;"
                v-else
              />
              <h5 class="font-medium mx-2 mb-0">
                {{ getUser.user_business.business_name }}
              </h5>
            </div>
            <b-button
              class="mx-3 rounded-circle md__btn-primary"
              id="profile-image"
              ><img
                :src="getUser.image"
                :alt="getUser.name"
                :title="getUser.name"
                v-if="getUser.image"
              />
              <img
                class="not-image-profile"
                :src="icoUserLine"
                alt="ico user"
                width="24"
                v-else
              />
            </b-button>
            <span id="popover-profile">
              <IconMoreOptionComponent size="small" theme="blue" />
            </span>

            <b-popover
              target="popover-profile"
              triggers="hover"
              placement="bottomleft"
            >
              <template v-slot:title>
                <b-row class="align-items-center py-2 pl-2">
                  <b-col sm="7">
                    <h6>{{ getUser.name }}</h6>
                    <p class="small">{{ getUser.email }}</p>
                  </b-col>
                  <b-col sm="5">
                    <b-button
                      @click.prevent="profile"
                      size="sm"
                      class="btn md__btn-primary w-100 p-0"
                      >Perfil</b-button
                    >
                  </b-col>
                </b-row>
              </template>
              <user-content-popover :user="this.getUser"></user-content-popover>
            </b-popover>
            <!-- <div class="notificaciones position-relative ml-4">
              <span>2</span>
              <img :src="icoBell" alt="ico bell" width="24" />
            </div> -->
          </div>
        </div>
      </section>
    </header>
    <TransitionFadeComponent>
      <SubMenuComponent />
    </TransitionFadeComponent>
  </div>
</template>

<script>
import SubMenuComponent from "@/components/SubMenu/SubMenu";
import LogoComponent from "@/components/Logo/Logo";
import IconMoreOptionComponent from "@/components/General/IconMoreOption";
import TransitionFadeComponent from "@/components/Transitions/Fade";
import NavbarResponsiveComponent from "./components/NavbarResponsive";
import NavbarItemsComponent from "./components/NavbarItems";
import UserContentPopover from "./components/UserContentPopover";
import { icoBell, icoOption, icoUserLine } from "@/services/resources";
import TextAvatarComponent from "../../modules/dashboard/sales/components/TextAvatar";

export default {
  name: "DashboardHeaderComponent",
  data: () => ({
    icoBell,
    icoOption,
    icoUserLine
  }),
  methods: {
    onClickLogo() {
      this.$router.push({ name: "dashboard" });
    },
    profile() {
      this.$router.push({ name: "profile" });
    },
    onToggleSubMenu(property) {
      this[property] = !this[property];
      this.toggleSubMenu = this[property];
    }
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    }
  },
  components: {
    SubMenuComponent,
    LogoComponent,
    IconMoreOptionComponent,
    TransitionFadeComponent,
    NavbarResponsiveComponent,
    NavbarItemsComponent,
    UserContentPopover,
    TextAvatarComponent
  }
};
</script>

<style scoped lang="scss">
header {
  position: relative;

  .md__nav_main-items-right {
    position: absolute;
    right: 0;
    top: 0;
  }

  .notificaciones {
    span {
      border: #fff 2px solid;
      border-radius: 50%;
      color: #fff;
      background: #01ea75;
      padding: 1px 6px;
      font-size: 10px;
      position: absolute;
      top: -8px;
      left: 18px;
    }
  }

  .bell-alert {
    color: #e3e3e3;
    font-size: 26px;
  }

  #profile-image {
    // border: 4px solid #fff;
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    img {
      transition: all 0.2s ease-in;
      width: 40px;
      height: 40px;
      border-radius: 50%;

      &.not-image-profile {
        width: 30px;
        height: 30px;
        border-radius: 0%;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.btn-a {
  display: flex;
  align-items: center;
}

#btn-profile-popover {
  display: flex;
  justify-content: center;
  min-height: 40px !important;
}

.image-logo-header {
  padding: 0;
  width: 30px;
  height: 30px;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

.business-main {
  border-radius: 5px;
  background: #fff;
  color: black;
}

.business-selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgrey;

  h5 {
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .sticky-custom {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
</style>
